//

import { openDB } from 'idb';

const DB_NAME = 'orchard-scout-database';
const DB_VERSION = 1;

export const INDEX_DB_CONFIG = {
  customers: { storeObject: 'customers', keyPath: 'id', hasIndex: false },
  peoples: { storeObject: 'peoples', keyPath: 'id', hasIndex: false },
  prospectCustomers: {
    storeObject: 'prospectCustomers',
    keyPath: 'id',
    hasIndex: false,
  },
  prospectPeoples: {
    storeObject: 'prospectPeoples',
    keyPath: 'id',
    hasIndex: false,
  },
  cropLocations: {
    storeObject: 'cropLocations',
    keyPath: 'id',
    hasIndex: true,
    cropLocationsIndex: {
      customersKey: {
        indexKey: 'customer_id',
        unique: false,
      },
    },
  },
  cropLocationBlocks: {
    storeObject: 'cropLocationBlocks',
    keyPath: 'id',
    hasIndex: true,
    cropLocationBlocksIndex: {
      cropLocationsKey: {
        indexKey: 'crop_location_id',
        unique: false,
      },
      cropCommoditiesKey: {
        indexKey: 'crop_commodity_id',
        unique: false,
      },
    },
  },
  cropCommodities: {
    storeObject: 'cropCommodities',
    keyPath: 'id',
    hasIndex: true,
    cropCommoditiesIndex: {
      cropCommodityTypesKey: {
        indexKey: 'crop_commodity_type_id',
        unique: false,
      },
    },
  },
  questions: {
    storeObject: 'questions',
    keyPath: 'id',
    hasIndex: true,
    questionsIndex: {
      positionsKey: {
        indexKey: 'position',
        unique: true,
      },
    },
  },
  existingReports: {
    storeObject: 'existingReports',
    keyPath: 'id',
    hasIndex: true,
    existingReportsIndex: {
      customersKey: {
        indexKey: 'customer_id',
        unique: false,
      },
      cropLocationsKey: {
        indexKey: 'crop_location_id',
        unique: false,
      },
    },
  },
  scoutInventoryTypes: {
    storeObject: 'scoutInventoryTypes',
    keyPath: 'id',
    hasIndex: false,
  },
  scoutInventories: {
    storeObject: 'scoutInventories',
    keyPath: 'id',
    hasIndex: true,
    scoutInventoriesIndex: {
      inventoryKey: {
        indexKey: 'inventory_type_id',
        unique: false,
      },
    },
  },
  singleExistingReports: {
    storeObject: 'singleExistingReports',
    keyPath: 'id',
    hasIndex: false,
  },
  offlineSubmittedReports: {
    storeObject: 'offlineSubmittedReports',
    keyPath: 'uuid',
    hasIndex: true,
  },
  offlineUpdatedReports: {
    storeObject: 'offlineUpdatedReports',
    keyPath: 'uuid',
    hasIndex: true,
  },
  offlineInventoryReports: {
    storeObject: 'offlineInventoryReports',
    keyPath: 'uuid',
    hasIndex: true,
  },
  offlineInventoryItems: {
    storeObject: 'offlineInventoryItems',
    keyPath: 'uuid',
    hasIndex: true,
  },
  syncReport: {
    storeObject: 'syncReport',
    keyPath: null,
    hasIndex: false,
  },
};

// initiate the database for new devices ...
export async function openDatabase() {
  const indexDB = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('customers')) {
        db.createObjectStore('customers', { keyPath: 'id' });
      }

      if (!db.objectStoreNames.contains('prospectCustomers')) {
        db.createObjectStore('prospectCustomers', { keyPath: 'id' });
      }

      if (!db.objectStoreNames.contains('peoples')) {
        db.createObjectStore('peoples', { keyPath: 'id' });
      }

      if (!db.objectStoreNames.contains('prospectPeoples')) {
        db.createObjectStore('prospectPeoples', { keyPath: 'id' });
      }

      if (!db.objectStoreNames.contains('cropLocations')) {
        const cropLocationsStore = db.createObjectStore('cropLocations', {
          keyPath: 'id',
        });
        cropLocationsStore.createIndex('customer_id', 'customer_id', {
          unique: false,
        });
      }

      if (!db.objectStoreNames.contains('cropLocationBlocks')) {
        const cropLocationBlocksStore = db.createObjectStore(
          'cropLocationBlocks',
          { keyPath: 'id' }
        );
        cropLocationBlocksStore.createIndex(
          'crop_location_id',
          'crop_location_id',
          { unique: false }
        );
        cropLocationBlocksStore.createIndex(
          'crop_commodity_id',
          'crop_commodity_id',
          { unique: false }
        );
      }

      if (!db.objectStoreNames.contains('cropCommodities')) {
        const cropCommoditiesStore = db.createObjectStore('cropCommodities', {
          keyPath: 'id',
        });
        cropCommoditiesStore.createIndex(
          'crop_commodity_type_id',
          'crop_commodity_type_id',
          { unique: false }
        );
      }

      if (!db.objectStoreNames.contains('questions')) {
        const questionsStore = db.createObjectStore('questions', {
          keyPath: 'id',
        });
        questionsStore.createIndex('position', 'position', { unique: true });
      }

      if (!db.objectStoreNames.contains('existingReports')) {
        const existingReportsStore = db.createObjectStore('existingReports', {
          keyPath: 'id',
        });
        existingReportsStore.createIndex('customer_id', 'customer_id', {
          unique: false,
        });
        existingReportsStore.createIndex(
          'crop_location_id',
          'crop_location_id',
          { unique: false }
        );
      }

      if (!db.objectStoreNames.contains('scoutInventoryTypes')) {
        db.createObjectStore('scoutInventoryTypes', { keyPath: 'id' });
      }

      if (!db.objectStoreNames.contains('scoutInventories')) {
        const scoutInventoriesStore = db.createObjectStore('scoutInventories', {
          keyPath: 'id',
        });
        scoutInventoriesStore.createIndex(
          'inventory_type_id',
          'inventory_type_id',
          {
            unique: false,
          }
        );
      }

      if (!db.objectStoreNames.contains('singleExistingReports')) {
        db.createObjectStore('singleExistingReports', {
          keyPath: ['reportId', 'questionId'],
        });
      }

      if (!db.objectStoreNames.contains('offlineSubmittedReports')) {
        const offlineSubmittedReportsStore = db.createObjectStore(
          'offlineSubmittedReports',
          { keyPath: 'uuid' }
        );
        offlineSubmittedReportsStore.createIndex('uuid', 'uuid', {
          unique: true,
        });
      }

      if (!db.objectStoreNames.contains('offlineUpdatedReports')) {
        const offlineUpdatedReportsStore = db.createObjectStore(
          'offlineUpdatedReports',
          { keyPath: 'id' }
        );
        offlineUpdatedReportsStore.createIndex('id', 'id', { unique: true });
      }

      if (!db.objectStoreNames.contains('offlineInventoryReports')) {
        const offlineSubmittedInventoriesStore = db.createObjectStore(
          'offlineInventoryReports',
          { keyPath: 'uuid' }
        );
        offlineSubmittedInventoriesStore.createIndex('uuid', 'uuid', {
          unique: true,
        });
      }

      if (!db.objectStoreNames.contains('offlineInventoryItems')) {
        const offlineSubmittedInventoryItemsStore = db.createObjectStore(
          'offlineInventoryItems',
          { keyPath: 'uuid' }
        );
        offlineSubmittedInventoryItemsStore.createIndex('uuid', 'uuid', {
          unique: true,
        });
      }

      if (!db.objectStoreNames.contains('syncReport')) {
        db.createObjectStore('syncReport');
      }
    },
  });

  return indexDB;
}

// delete the database. like, when user logs out from the app ...
export function clearDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(DB_NAME);

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}
